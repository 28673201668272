import React, { PropsWithChildren } from "react";

interface IProps {
    enabled?: boolean;
    closePopup: () => void;
}

export const PopupModal = (props: PropsWithChildren<IProps>) => {
    const { closePopup, children, enabled } = props;

    const classNames = ["popup-modal", enabled ? "enabled" : "disabled"];

    return (
        <div className={classNames.join(" ")} onClick={closePopup}>
            {children}
        </div>
    );
};
