import React, { PropsWithChildren } from "react";

interface IProps {
    onClick: () => void;
}

export const CloseButton = (props: PropsWithChildren<IProps>) => {
    const { onClick } = props;

    return (
        <a className="close" onClick={onClick}>
            &times;
        </a>
    );
};
