import React, { PropsWithChildren } from "react";
import { FooterDivider } from "./FooterDivider";

interface IProps {
    openTerms?: () => void;
    openPrivacy?: () => void;
    terms?: string;
    privacy?: string;
}

export const Footer = (props: PropsWithChildren<IProps>) => {
    const { openTerms, openPrivacy, terms, privacy } = props;

    return (
        <footer>
            <span>&copy; {new Date().getFullYear()} Like a Photon Creative</span>
            <FooterDivider />
            <a href={privacy} onClick={openPrivacy}>
                Privacy Policy
            </a>
            <FooterDivider />
            <a onClick={openTerms} href={terms}>
                Terms &amp; Conditions
            </a>
        </footer>
    );
};
