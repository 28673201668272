import React, { CSSProperties, PropsWithChildren, useEffect, useRef, useState } from "react";
import { generateMask } from "../utils/generateMask";

interface IProps {
    src: string;
    maskSrc: string;
    classNames?: string[];
}

const isBrowser = typeof window !== "undefined";

export const CanvasMaskDiv = (props: PropsWithChildren<IProps>) => {
    const { src, maskSrc, classNames: propertyClassNames, children } = props;
    const targetReference = useRef<HTMLImageElement>(null);
    const [masked, setMasked] = useState(false);
    const [generatedSourceUrl, setGeneratedSourceUrl] = useState<string | undefined>(undefined);

    const classNames = [...(propertyClassNames || []), "canvas-mask-div", masked ? "masked" : "unmasked"];

    const applyMask = async () => {
        if (isBrowser) {
            if (targetReference.current) {
                const newGeneratedSourceUrl = await generateMask(src, maskSrc);
                setGeneratedSourceUrl(newGeneratedSourceUrl);
                setMasked(true);
            }
        }
    };

    useEffect(() => {
        applyMask();
    }, [props]);

    const style: CSSProperties = {};
    if (generatedSourceUrl) {
        style.backgroundImage = `url(${generatedSourceUrl})`;
    }

    return (
        <div className={classNames.join(" ")}>
            <div ref={targetReference} className={"canvas-mask-target"} style={style}>
                {children}
            </div>
        </div>
    );
};
