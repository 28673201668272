export const generateMask = (sourceUrl: string, maskSourceUrl: string) => {
    return new Promise<string>((resolve, reject) => {
        const imageCanvas = document.createElement("canvas");
        const imageContext = imageCanvas.getContext("2d") as CanvasRenderingContext2D;
        const newImg = document.createElement("img");
        newImg.src = sourceUrl;

        const onMaskLoad = (mask: HTMLImageElement, width: number, height: number) => {
            imageCanvas.width = width;
            imageCanvas.height = height;

            imageContext.drawImage(mask, 0, 0, width, height);
            imageContext.globalCompositeOperation = "source-in";
            imageContext.drawImage(newImg, 0, 0);

            resolve(imageCanvas.toDataURL());
        };

        const onImageLoad = () => {
            const width = newImg.width;
            const height = newImg.height;

            const mask = document.createElement("img");
            mask.src = maskSourceUrl;
            mask.addEventListener("load", () => {
                onMaskLoad(mask, width, height);
            });
            mask.addEventListener("error", reject);
        };

        newImg.addEventListener("load", onImageLoad);
        newImg.addEventListener("error", reject);
    });
};
