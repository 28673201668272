import React, { PropsWithChildren } from "react";
import { CanvasMaskDiv } from "./CanvasMaskDiv";

interface IProps {
    label: string;
    link?: string;
}

export const Title = (props: PropsWithChildren<IProps>) => {
    const { label, link } = props;

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const source = require("../images/logo_1024x305.jpg");
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const maskSource = require("../images/logo_mask_1024x305.png");

    const title = (
        <CanvasMaskDiv src={source} maskSrc={maskSource}>
            <h1>{label}</h1>
        </CanvasMaskDiv>
    );

    return <header>{link ? <a href={link}>{title}</a> : <>{title}</>}</header>;
};
