import React, { PropsWithChildren } from "react";
import { CloseButton } from "./CloseButton";

interface IProps {
    label: string;
    enabled?: boolean;
    closePopup: () => void;
}

export const Popup = (props: PropsWithChildren<IProps>) => {
    const { closePopup, children, label, enabled } = props;

    const classNames = ["popup", enabled ? "enabled" : "disabled"];

    return (
        <div className={classNames.join(" ")}>
            <h2 className={"popup-label"}>{label}</h2>
            <CloseButton onClick={closePopup} />
            <div className={"popup-inner"}>
                <div className={"popup-scroll"}>{children}</div>
            </div>
        </div>
    );
};
