import React, { PropsWithChildren } from "react";
import "../scss/index.scss";
import { Title } from "./Title";

interface IProps {
    label: string;
    linkTitle?: string;
}

export const Layout = (props: PropsWithChildren<IProps>) => {
    const { label, children, linkTitle } = props;

    return (
        <section className={"container-holder"}>
            <section className={"container"}>
                <Title label={label} link={linkTitle} />
                {children}
            </section>
        </section>
    );
};
