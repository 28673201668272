import React from "react";

export const PrivacyPolicy = () => {
    return (
        <div className="text">
            <h3>Introduction</h3>
            <p>
                1.1 This Policy relates to the website Sanctuary World at www.sanctuaryworld.com and the Sanctuary World App, operated by Like A Photon Creative Pty Ltd (ACN: 159 756 279). A reference to we, our or us is a reference to Like A Photon, and a reference to Sanctuary World is a reference to the website or the app, whichever you are using.
            </p>

            <p>1.2 Personal Information is any information about a person where their identity is apparent, or can reasonably be ascertained. We do not solicit nor knowingly collect Personal Information from children aged under 13.</p>

            <p>
                1.3 Sensitive Information is information or an opinion about a person’s racial or ethnic origin, political opinions, membership of a political association, religious beliefs or affiliations, philosophical beliefs, sexual preferences, health or medical information or criminal records. We never solicit the disclosure of Sensitive Information
                and we do not knowingly collect Sensitive Information from any User.
            </p>

            <h3>What this Policy is about</h3>

            <p>2.1 Sanctuary World is aimed at preschool and primary-school aged children (Child Users), under supervision by their parents/guardians (Parent Users). Child Users and Parent Users are referred to collectively as Users or you.</p>

            <p>2.2 This Policy explains the key measures we have taken to implement the requirements of the Privacy Act 1988 and the Australian Privacy Principles, and to keep Sanctuary World safe, appropriate and enjoyable for all Users.</p>

            <p>
                2.3 We endorse fair information handling practices and uses of information in compliance with our obligations under the privacy laws in force in Australia from time to time. Any information provided, including Personal Information, will be used only for the purpose/s intended and where the intention includes confidentiality, information will
                be treated as such unless otherwise required by law.
            </p>

            <p>2.4 We will treat all Personal Information in a manner consistent with this Policy unless you have provided your express consent otherwise.</p>

            <h3>Your child’s information</h3>

            <p>3.1 We do not intentionally actively collect any information from Child Users and we will never intentionally or knowingly collect, share or use the Personal Information of Child Users.</p>

            <h3>Collection of Personal Information</h3>

            <p>4.1 The Company may collect Personal Information from a Parent User including name, email and contact address. This information is collected by soliciting it directly from the Parent User, or when the Parent User provides it directly to us through Sanctuary World or by emailing or phoning us.</p>

            <p>4.2 We do not collect credit card or other financial information. All financial transactions related to or originating from Sanctuary World are managed by a secure third-party gateway on a third-party site or platform.</p>

            <p>4.3 Sanctuary World does not yet have interactivity such as messaging or online discussion tools such as blogging, reviewing, commenting and forums. If these features are introduced in future we will update this Policy accordingly.</p>

            <p>4.4 Sanctuary World automatically collects anonymous usage data about Users, including the URL that the User came from, the browser being used and the IP address. This data is utilised to improve our services and does not include any personally identifying information.</p>

            <p>4.5 The collection and analysis of the data referred to in clause 4.4 above may be outsourced to a third party contractor that operates on our behalf. We will ensure that any such third- party treats all Personal Information in accordance with this Privacy Policy and Australian law.</p>

            <p>
                4.6 ‘Cookies’ are alphanumeric identifiers that are stored by the web browser on a computer’s hard-drive that enable our system to recognise a returning User. This helps us to track basic User information for the purposes of optimising the design of our systems and marketing activities. We may also use cookies to assist in any financial
                transactions that originate on our Site but are managed by third party gateways.
            </p>

            <p>
                4.7 Most web browsers automatically accept cookies and this function can be disabled by changing the browser settings of the user. However, you may find that disabling cookies may reduce the operability of Sanctuary World. For example, you will need to enter your log-in- details each time you visit the page, or you may be unable to log in.
            </p>

            <h3>Use of Personal Information</h3>

            <p>5.1 The Company uses Personal Information for:</p>

            <ul>
                <li>sending information, newsletters and briefings to Parent Users;</li>
                <li>replying to correspondence from Parent Users;</li>
                <li>processing orders and sending information and updates pertaining to orders to Parent Users;</li>
                <li>advertising products or services being offered by us or any of our related companies or business</li>
                <li>partners; and</li>
                <li>notifying Parent Users of updates of Sanctuary World and this Privacy Policy.</li>
            </ul>

            <p>5.2 When we use Personal Information to send you a commercial electronic message, we will:</p>
            <ul>
                <li>always identify who has sent you the message;</li>
                <li>never use false or misleading subjects or email addresses;</li>
                <li>allow users to unsubscribe; and</li>
                <li>honour opt-out/unsubscribe requests within five business days.</li>
            </ul>

            <p>If at any time you would like to unsubscribe from receiving future emails, you can email us at hello@likeaphoton.com</p>

            <p>5.3 We do not pass on any Personal Information to a third party except in accordance with this Privacy Policy.</p>

            <h3>Disclosure of Personal Information</h3>

            <p>
                6.1 Other than disclosure to service providers (as set out in 6.2 below) or as required by law (for example, disclosure to various Government departments or to Courts), our policy is that we do not give Personal Information to other organisations unless we have disclosed the use in this Policy or you have expressly consented for us to do so.
            </p>

            <p>6.2 We may also use Personal Information we collect for related purposes such as:</p>
            <ul>
                <li>to record information about a User’s usage, preferences and behaviour, as well as any feedback provided by Users;</li>
                <li>to perform statistical analyses of user behaviour;</li>
                <li>to optimise marketing activities, user experience, and content;</li>
                <li>protecting individuals and users from fraud; and</li>
                <li>any other use for which we obtain permission from you.</li>
            </ul>

            <p>6.3 The parties we may share Personal Information with are our employees, subcontractors, suppliers and affiliates on a need to know basis. Access to Personal Information will be revoked within a reasonable timeframe of access no longer being required.</p>

            <p>6.4 Occasionally, we might also use Personal Information for other purposes or share Personal Information with another organisation because:</p>

            <ul>
                <li>we believe it is necessary to protect your rights, property or personal safety;</li>
                <li>we believe it is necessary to do so to prevent or help detect fraud or serious credit infringements – for example, we may share information with other, credit reporting agencies, law enforcement agencies and fraud prevention units; or</li>
                <li>we believe it is necessary to protect our interests – for example, disclosure to a Court in the event of legal action to which we are a party;</li>
            </ul>

            <p>6.5 When we share information with other organisations and service providers as set out above, we do so in accordance with this Policy. To the extent that these organisations and service providers gain access to Personal Information, their use is governed by the provisions of the Privacy Act 1988.</p>

            <h3>Confidentiality and Data Security</h3>

            <p>7.1 We never solicit nor intentionally collect nor store Sensitive Information from any User.</p>

            <p>7.2 Personal Information may be stored on secure servers located outside Australia.</p>

            <p>7.3 We take all reasonable steps to manage data stored on our servers to ensure data security and to prevent the loss, misuse or alteration of Personal Information. Notwithstanding the above, we are not responsible for any third-party access to Personal Information as a result of:</p>

            <ul>
                <li>interception while it is in transit over the internet;</li>
                <li>spyware or viruses on the device (such as a computer or phone) from which Users access Sanctuary World; or</li>
                <li>as a result of a User’s failure to adequately protect their username or password.</li>
            </ul>

            <p>7.4 We are also not responsible for any losses, expenses, damages and costs, including legal fees, resulting from such third-party access.</p>

            <p>7.5 If a data breach occurs, we will notify the Office of the Australian Information Commissioner, and, if necessary, Users, via in-Site notification.</p>

            <h3>Retention and Disposal of Personal Information</h3>

            <p>8.1 We will retain Personal Information for as long as is required for us to fulfil the purposes for which the Personal Information was collected, including to comply with legal requirements.</p>

            <p>8.2 If we no longer require Personal Information for any purpose, including legal purposes, we will take reasonable steps to securely destroy or permanently de-identify the Personal Information.</p>

            <p>8.3 Personal Information is backed up frequently and tested regularly in line with our standard backup procedures. Personal Information that has been deleted may, therefore, persist within backups for a period of time after which it falls outside the backup rotation.</p>

            <h3>Access to Personal Information</h3>

            <p>9.1 You can access the Personal Information held about you or the Child User at any time by contacting our Privacy Officer at privacy@likeaphoton.com</p>

            <p>9.2 We will always endeavour to meet requests for access. However, in some circumstances, we may decline a request for access. This includes the following circumstances:</p>
            <ul>
                <li>we no longer hold or use the information;</li>
                <li>we no longer hold or use the information;</li>
                <li>providing access would have an unreasonable impact on the privacy of other persons;</li>
                <li>the request is frivolous or vexatious;</li>
                <li>the information relates to existing or anticipated legal proceedings and would not normally be disclosed as part of those proceedings;</li>
                <li>providing access would be unlawful;</li>
                <li>providing access would be likely to prejudice the detection, prevention, investigation and prosecution of possible unlawful activity; and</li>
                <li>the information would reveal other User’s commercially sensitive information.</li>
            </ul>

            <p>9.3 If we decline a request for access, we will provide reasons for our decision when we respond to the request.</p>

            <p>9.4 We reserve the right to charge you a reasonable fee for access to your Personal Information. These charges will be limited to the cost of recouping our expenses for providing you with your Personal Information, such as document retrieval, photocopying, labour and delivery.</p>

            <h3>Changing or deleting Personal Information</h3>

            <p>10.1 We will take reasonable steps to ensure that Personal Information is accurate, complete and up-to-date at the time of collecting the Personal Information from you or during other interactions with you.</p>

            <p>10.2 If you believe that any Personal Information we hold about you is inaccurate, incomplete or out-of-date, you may contact our Privacy Officer.</p>

            <p>10.3 We will do our best to correct any Personal Information that is inaccurate, incomplete or out-of-date or dispose of it in accordance with this Policy.</p>

            <h3>Contact Information and Changes to Privacy Policy</h3>

            <p>11.1 If you have any further queries relating to our Privacy Policy, please contact our Privacy Officer. If we become aware of any ongoing concerns or problems with your Personal Information, we will take these issues seriously and work to address these concerns.</p>

            <p>11.2 If you have a complaint in relation to the way your Personal Information has been handled by us, the complaint should be made in writing to our Privacy Officer in the first instance. we will investigate the complaint and prepare a response to you in writing within a reasonable period of time.</p>

            <p>11.3 Our Privacy Officer can be contacted by emailing hello@likeaphoton.com</p>

            <p>11.4 From time to time, our policies will be reviewed and may be revised. We reserve the right to change this Policy at any time.</p>

            <p>This Privacy Policy was last updated in December 2019.</p>
        </div>
    );
};
