import React, { PropsWithChildren } from "react";
import ReactGA from "react-ga";

interface IProps {
    playStoreUrl: string;
    appStoreUrl: string;
}

export const DownloadIcons = (props: PropsWithChildren<IProps>) => {
    const { playStoreUrl, appStoreUrl } = props;

    const onAndroid = () => {
        ReactGA.event({
            category: "Download Android App",
            action: "Visit Google Play Store",
        });
    };

    const onIos = () => {
        ReactGA.event({
            category: "Download iPhone App",
            action: "Visit Apple App Store",
        });
    };

    return (
        <div className={"download-icons"}>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a className={"google"} href={playStoreUrl} target={"_blank"} onClick={onAndroid}>
                Get it on Google Play
            </a>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a className={"apple"} href={appStoreUrl} target={"_blank"} onClick={onIos}>
                Download on the App Store
            </a>
        </div>
    );
};
