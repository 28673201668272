import React, { PropsWithChildren, useEffect, useState } from "react";
import { DownloadIcons } from "./DownloadIcons";
import { Layout } from "./Layout";
import { PopupModal } from "./PopupModal";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { SEO } from "./SEO";
import ReactGA from "react-ga";
import { Popup } from "./Popup";
import { Footer } from "./Footer";
import { TermsAndConditions } from "./TermsAndConditions";

export enum PopupState {
    None,
    Privacy,
    TermsAndConditions,
}

interface IProps {
    initialPopupState: PopupState;
}

export const Page = (props: PropsWithChildren<IProps>) => {
    const { initialPopupState } = props;
    const [popupState, setPopupState] = useState<PopupState>(initialPopupState);

    useEffect(() => {
        ReactGA.initialize(`${process.env.GATSBY_GOOGLE_ANALYTIC_ID}`);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const closePopup = () => {
        setPopupState(PopupState.None);
    };

    const privacyOn = () => {
        setPopupState(PopupState.Privacy);
    };

    const termsOn = () => {
        setPopupState(PopupState.TermsAndConditions);
    };

    const playStoreUrl = `${process.env.GATSBY_GOOGLE_PLAY_URL}`;
    const appStoreUrl = `${process.env.GATSBY_APP_STORE_URL}`;

    return (
        <>
            <SEO title={"Home"} lang={"en"} meta={[]} />
            <Layout label={"Sanctuary World - Match 3 City Building Game"}>
                <DownloadIcons playStoreUrl={playStoreUrl} appStoreUrl={appStoreUrl} />
                <Footer openTerms={termsOn} openPrivacy={privacyOn} />
            </Layout>
            <PopupModal enabled={popupState !== PopupState.None} closePopup={closePopup} />
            <Popup closePopup={closePopup} label={"Terms & Conditions"} enabled={popupState === PopupState.TermsAndConditions}>
                <TermsAndConditions />
            </Popup>
            <Popup closePopup={closePopup} label={"Privacy Policy"} enabled={popupState === PopupState.Privacy}>
                <PrivacyPolicy />
            </Popup>
        </>
    );
};
